var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_consumed_product") + ": " + _vm.propProductCode,
        width: "75%",
        closable: false
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "CGenerateBatchNumber",
                {
                  on: { "on-search": _vm.onSearchBatch },
                  model: {
                    value: _vm.vmBatchNumber,
                    callback: function($$v) {
                      _vm.vmBatchNumber =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "vmBatchNumber"
                  }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "button-text" }, slot: "button-text" },
                    [_vm._v(_vm._s(_vm.$t("lbl_pick")))]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _vm.alertVisible
                ? _c("a-alert", {
                    attrs: {
                      message: _vm.$t("lbl_batch_is_not_found"),
                      type: "error",
                      "show-icon": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "reload", loading: _vm.isSearching },
                  on: { click: _vm.refresh }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_refresh")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    loading: _vm.isSearching,
                    size: "small",
                    scroll: { y: 520, x: "calc(600px + 50%)" },
                    pagination: false,
                    "row-selection": _vm.rowSelection,
                    "row-class-name": _vm.rowClassName
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "product.code",
                      attrs: { "data-index": "product.code" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.product.code || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber", width: 280 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.batchNumber || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "packDate",
                      attrs: { "data-index": "packDate" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(record.packDate)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_pack_date")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "available",
                      attrs: { "data-index": "available" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toDecimalQty")(record.available)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_available")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "warehouseLocationName",
                      attrs: { "data-index": "warehouseLocationName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(record.warehouseLocationName || "-") +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_rack")))
                      ])
                    ]
                  ),
                  _c("template", { slot: "footer" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("lbl_total_selected_bn")) +
                          ": " +
                          _vm._s(_vm.totalBnSelected)
                      )
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lbl_total_qty_available", {
                              data: _vm.formatDecimalQty(_vm.totalBnQty)
                            })
                          ) +
                          " "
                      )
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            _vm._l(_vm.selectedRow, function(batch) {
              return _c(
                "a-button",
                {
                  key: batch.id,
                  staticClass: "mr-2 mb-2",
                  attrs: { type: "dashed" }
                },
                [_vm._v(" " + _vm._s(batch.batchNumber || "-") + " ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            justify: "space-between",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtInventory.totalElements,
                  "page-size-set": _vm.queryParams.limit,
                  "default-current": _vm.queryParams.page + 1,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _c("a-tag", { attrs: { color: "grey" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtInventory.totalElements) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.cancel } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: { type: "primary", icon: "save" },
              on: { click: _vm.save }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }