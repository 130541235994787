










































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { ResponseInventoryLineBatch, ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { ResponseBatch } from "@/models/interface-v2/batch.interface";
import { Decimal } from "decimal.js-light";
import { formatDecimalQty } from "@/helpers/common";
interface Row extends ResponseInventoryLineBatch {
  key: any;
  found: boolean;
}

const HIGHLIGHT = 2000;

export default Vue.extend({
  name: "ModalLocationBatch",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    propProductCode: {
      type: String,
      default: undefined,
    },
    propDefault: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      dtSource: [] as Row[],
      loading: false,
      queryParams: {
        page: 0,
        limit: 10,
        search: "available>0_AND_batch.status~USED",
        sorts: "batch.packDate:asc,modifiedDate:asc",
      },
      dtInventory: {} as ResponseListInventoryLineBatch,
      selectedRowKeys: [] as string[],
      selectedRow: [] as Row[],
      vmBatchNumber: "",
      idTimeout: null as null | number,
      stateFinder: "idle" as "idle" | "searching" | "not_found",
    };
  },
  computed: {
    rowSelection() {
      return {
        hideDefaultSelections: true,
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.onSelect,
      };
    },
    totalBnQty(): number {
      return this.selectedRow.reduce((a, b) => new Decimal(b.available).plus(a).toNumber(), 0);
    },
    totalBnSelected(): number {
      return this.selectedRow.length;
    },
    alertVisible(): boolean {
      return this.stateFinder === "not_found";
    },
    isSearching(): boolean {
      return this.stateFinder === "searching";
    },
  },
  watch: {
    propProductCode(newValue: string): void {
      if (newValue) {
        this.queryParams.page = 0;
        this.queryParams.search = `product.code~${this.propProductCode}_AND_available>0_AND_batch.status~USED`;
        this.getList(this.queryParams);
      }
    },
    "propDefault": {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.selectedRow = newValue.batchs;
        this.selectedRowKeys = newValue.batchs.map(x => x.batchId);
      }
    }
  },
  created() {
    if (this.propProductCode) {
      this.queryParams.search = `product.code~${this.propProductCode}_AND_available>0_AND_batch.status~USED`;
    }
    this.getList(this.queryParams);
  },
  beforeDestroy() {
    if (this.idTimeout) {
      clearTimeout(this.idTimeout);
    }
  },
  methods: {
    formatDecimalQty,
    getListInventoryLinesBatch(params: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(params);
    },
    save(): void {
      this.$emit("input", false);
      this.$emit("on-save", {
        value: this.selectedRow
      });
    },
    cancel(): void {
      this.$emit("input", false);
    },
    onSelectChange(selectedRowKeys: string[]): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    rowClassName(record: Row): string {
      return record.found ? "is-found" : "";
    },
    onSelect(record: Row, selected: boolean): void {
      const { selectedRow } = this;
      if (selected) {
        this.selectedRow = [...selectedRow, record];
      } else {
        this.selectedRow = selectedRow.filter(x => x.key !== record.key);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.stateFinder = "searching";
        this.loading = true;
        const res = await this.getListInventoryLinesBatch(params);
        this.dtSource = res.data.map((x, i) => ({
          key: x.batchId,
          found: false,
          ...x
        }));
        this.dtInventory = res;
      } catch(error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
        this.stateFinder = "idle";
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination, load = true): void {
      this.queryParams.page = response.page - 1;
      if (!load) return;
      this.getList(this.queryParams);
    },
    onSearchBatch({ batch }: { batch: ResponseBatch }): void {
      const row = this.dtSource.find(x => x.batchId === batch.id);
      this.stateFinder = "searching";
      if (row) {
        row.found = true;
        if (!this.selectedRowKeys.includes(batch.id)) {
          this.selectedRowKeys.push(batch.id);
          this.onSelect(row, true);
        }
        this.idTimeout = setTimeout(() => {
          row.found = false;
        }, HIGHLIGHT);
        this.stateFinder = "idle";
        this.vmBatchNumber = "";
      } else {
        this.queryParams.page = 0;
        this.findBatch(batch.id);
      }
    },
    async findBatch(batchId: string): Promise<void> {
      try {
        this.stateFinder = "searching";
        this.loading = true;
        const res = await this.getListInventoryLinesBatch(this.queryParams);
        const dtSource = res.data.map((x, i) => ({
          key: x.batchId,
          found: false,
          ...x
        }));
        const row = dtSource.find(x => x.batchId === batchId);
        if (row) {
          row.found = true;
          if (!this.selectedRowKeys.includes(row.batchId)) {
            this.selectedRowKeys.push(row.batchId);
            this.onSelect(row, true);
          }
          this.dtSource = dtSource;
          const page = {
            page: res.currentPage + 1,
            idPagination: "",
            size: this.queryParams.limit
          };
          this.responseCurrentPageChange(page, false);
          this.idTimeout = setTimeout(() => {
            row.found = false;
          }, HIGHLIGHT);
          this.vmBatchNumber = "";
          this.stateFinder = "idle";
        } else if (this.queryParams.page + 1 === this.dtInventory.totalPages) {
          this.stateFinder = "not_found";
        } else {
          this.queryParams.page++;
          this.findBatch(batchId);
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async refresh(): Promise<void> {
      try {
        this.loading = true;
        this.queryParams.page = 0;
        this.stateFinder = "searching";
        const res = await this.getListInventoryLinesBatch(this.queryParams);
        this.dtSource = res.data.map((x) => ({
          key: x.batchId,
          found: false,
          ...x
        }));
        this.dtInventory = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
        this.stateFinder = "idle";
      }
    },
  }
});
